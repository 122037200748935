<template>
  <div class="report--container h-full">
    <div class="mb-4">
      <div class="filter-bar">
        <div v-if="!isSuperReport" class="filter-section">
          <font-awesome-icon icon="user" />
          <small>Client owner</small>
          <FilterOwner schema="client:client_owner" v-model="owner" />
        </div>

        <div v-if="!isSuperReport" class="filter-section">
          <font-awesome-icon icon="file" style="padding-top: 0.16em" />
          <small>Quote</small>
          <choose
            class="sm"
            schema="quote:quote_id"
            v-model="quote"
            placeholder="All quotes"
            multiple
            :filters="{ company_ids: `INSET${$store.state.session.company.company_id}` }"
          />
        </div>

        <div class="filter-section">
          <font-awesome-icon icon="clock" style="padding-top: 0.16em" />
          <small>Time range</small>
          <TimeRangeSelector v-model="timeRange" />
        </div>
      </div>
    </div>
    <div class="h-full w-full relative flex items-center justify-center overflow-hidden">
      <Sheets
        v-if="isCurrentReport"
        v-show="loading || contentRows.length"
        ref="sheet"
        :border-radius="5"
        :default-cell-height="40"
        :freeze="1"
        :sheets="reportSheets"
      />
      <p v-if="!loading && !contentRows.length">Nothing to report, try adjusting the filters</p>
    </div>
  </div>
</template>

<script setup>
import FilterOwner from '../filters/Owner.vue'
import Sheets from '@/components/Sheets/Sheets.vue'
import { ref, watch, computed } from 'vue'
import { useStore } from 'vuex'

// ===== props ===== //
const props = defineProps({
  reportType: {
    type: String,
    required: true
  },
  entity: {
    type: String,
    required: true
  },
  reportIndex: {
    type: Number,
    required: true
  },
  activeReportIndex: {
    type: Number,
    required: true
  },
  isSuperReport: {
    type: Boolean,
    default: false
  }
})
// ================= //

// ===== composables ===== //
const store = useStore()
// =================== //

// ====== Refs ====== //
const sheet = ref(null)
// =================== //

// ===== data ===== //
const reportAoa = ref([])
const sums = ref([])
const timeRange = ref('')
const owner = ref(null)
const quote = ref(null)
const loading = ref(false)
// ================= //

// ===== computed ====== //
const isCurrentReport = computed(() => props.reportIndex === props.activeReportIndex)
const titleRow = computed(() => {
  const aoa = _.imm(reportAoa.value)
  return aoa.length ? aoa[0] : []
})
const contentRows = computed(() => {
  const aoa = _.imm(reportAoa.value)
  if (!aoa.length) return []
  aoa.splice(0, 1)
  return aoa
})
const filters = computed(() => {
  const filters = []
  if (owner.value) {
    filters.push({
      table: 'clients',
      col: 'client_owner',
      value: owner.value
    })
  }
  if (quote.value) {
    filters.push({
      table: 'quotes',
      col: 'quote_id',
      value: quote.value
    })
  }
  if (timeRange.value) {
    filters.push({
      type: 'timeRange',
      table: `${props.entity}s`,
      col:
        props.reportType === 'projects' || props.reportType === 'costcertified_impact'
          ? 'quote_time_booked'
          : `${props.entity}_time_created`,
      value: timeRange.value
    })
  }
  return filters
})
const reportColumns = computed(() =>
  titleRow.value.map((title, index) => ({
    title: title,
    field: index.toString(),
    formatting: {
      width: 250,
      align: 'left',
      bold: true
    },
    readOnly: true
  }))
)
const reportItems = computed(() =>
  contentRows.value.map((item) => {
    const res = {}
    item.forEach((item, index) => {
      res[index.toString()] = item?.toString() || null
    })
    return res
  })
)
const reportSheets = computed(() => [
  {
    title: 'Report',
    sorting: [],
    grouping: [],
    rows: reportItems.value,
    columns: reportColumns.value,
    superHeaders: []
  }
])
// ==================== //

// ===== methods ===== //
const selectReport = async (force = false) => {
  loading.value = true
  if (sheet.value) sheet.value.loaded = false
  try {
    await c.throttle(
      async () => {
        const result = await fetchReport()
        reportAoa.value = result.report
        sums.value = result.sums
      },
      { delay: force ? 0 : 1000 }
    )
  } catch (err) {
    console.log(err)
  } finally {
    sheet.value?.reinitialize()
    loading.value = false
  }
}
const fetchReport = async () => {
  let payload = []
  try {
    const res = await store.dispatch('ajax', {
      path: '/reports/generateReport',
      data: {
        reportType: props.reportType,
        entity: props.entity,
        limit: 1000,
        filters: filters.value,
        orderBy: `${props.entity}_time_created DESC`
      }
    })
    if (res.payload) payload = res.payload
  } catch (e) {
    await store.dispatch(
      'alert',
      { error: true, message: e.userMessage || e.message },
      { root: true }
    )
  }
  return payload
}
// ================ //

// ===== watchers ===== //
watch(owner, () => selectReport(true))
watch(timeRange, () => selectReport(true))
watch(quote, () => selectReport(true))
// ================ //
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.report--container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  height: 100%;
  width: 100%;

  .report--scroll {
    height: 100%;
    width: 100%;
    flex-basis: 100%;

    table {
      user-select: text;
      margin-bottom: 10em;
      margin-right: 10em;
    }
  }
}

div.filter-bar {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}

.reports-floating-toolbar {
  min-height: 140px;
}

div.filter-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  height: 100%;
  padding: 0 2em;

  [data-icon] {
    font-size: 2em;
    min-height: 1.5em;
  }

  & + div.filter-section {
    border-left: 1px solid $cool-gray-400;
  }
}
</style>
